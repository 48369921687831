<template>
  <div class="container">
    <img class="box" src="./img/ljjbc.png" alt="" />
    <div class="imgBox">
      <img class="logo" src="./img/wxlogo.png" alt="" />
    </div>
    <div style="z-index: 99; position: absolute; top: 0">
      <div style="display: flex; align-items: center; flex-direction: column">
        <div
          style="
            padding-top: 2.7rem;
            padding-bottom: 0.34rem;
            font-size: 0.29rem;
            color: #9e9e9e;
          "
        >
          微信立减金
        </div>
        <div
          style="
            font-size: 0.56rem;
            color: #000000;
            font-weight: bold;
            padding-bottom: 0.5rem;
          "
        >
          微信立减金2元
        </div>
        <div style="padding-bottom: 0.68rem">
          <el-button
            :loading="btnloading"
            @click="goFn"
            style="height: 0.92rem; width: 4.752rem"
            type="primary"
            >去领取</el-button
          >
        </div>
      </div>
      <div style="padding: 0.47rem 0.68rem 0">
        <div
          style="
            font-size: 0.34rem;

            color: #000000;
            padding-bottom: 0.2rem;
          "
        >
          微信立减金使用说明
        </div>
        <div style="font-size: 0.26rem; line-height: 0.464rem; color: #000">
          1、微信立减金领取后7天有效，需满2.01元可使用;
        </div>
        <div style="font-size: 0.26rem; line-height: 0.464rem; color: #000">
          2、不得用于社交支付(个人转账、红包支付);
        </div>
        <div
          style="
            font-size: 0.26rem;
            line-height: 0.464rem;
            color: #000;
            word-break: break-all;
          "
        >
          3、使用范围:在中国境内商户使用微信支付即可使用，少数特定商户(包括但不限于:
          Coco
          奶茶、优衣库、星巴克、肯德基、必胜客、同程艺龙、热风、太平鸟、孩子王、航联保险、华为商城、Miss
          Sixty、Ochirly、Apple商城、Five
          Plus、零钱通、理财通、CovenGarden、Denham、广东电信、珠海城建、微众银行、分付、信用卡还款、腾安基金、哔哩哔哩、北京市医院管理中心、上海公共交通卡、深圳通、怪兽充电、迪卡侬等)无法使用立减金，社交支付(如转账、红包、个人收款码支付等)及合单支付(微信支付订单号为44开头的交易)无法使用立减金。具体请以微信支付收银台优惠提示信息为准；
        </div>
        <div style="font-size: 0.26rem; line-height: 0.464rem; color: #000">
          4、退款规则:如在券有效期内，当订单部分退款时，代金券金额按比例退还给用户，用户可在有效期内继续使用。如在券有效期内用户再次部分退款至整单退完，则会将代金券剩余部分退还给用户，用户可在有效期内继续使用。如退款时立减金代金券已过期，则不退还给用户；
        </div>
        <div style="font-size: 0.26rem; line-height: 0.464rem; color: #000">
          5、其他规则:每个用户（同一微信号、同一手机号、同一证件号、同一客户号等均视为同一客户）仅限领取一张，立减金仅限用户本人使用，不支持转发给他人，立减金不找零；
        </div>
        <div style="font-size: 0.26rem; line-height: 0.464rem; color: #000">
          6、微信立减金由河北沐米科技有限公司提供，使用问题工作日09:00-18:00请拔打客服电话
          4006126178；
        </div>
        <div style="font-size: 0.26rem; line-height: 0.464rem; color: #000">
          7、渤海银行7×24小时客服热线95541。
        </div>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 2rem;
        "
      >
        <img src="./img/buzhou.png" style="width: 5rem; height: auto" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import serve from "@/api/index";
export default {
  name: "HelloWorld",
  components: {},
  props: {},
  data() {
    return { code: "", btnloading: false, str: "", state: "" };
  },
  created() {
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var value1 = urlParams.get("hd");
    this.hdId = value1;
    console.log(this.hdId);
  },
  mounted() {
    // serve({
    //   url:
    //     "http:192.168.1.161:80/activity/user/sendOrder?openid=" +
    //     this.str +
    //     "&productid=" +
    //     this.state,
    //   method: "get",
    // }).then((res) => {
    //   console.log(res);
    //   this.btnloading = false;
    //   alert(res.data.msg);
    // });
    this.getCode();
  },
  methods: {
    getCode() {
      // 非静默授权，第一次有弹框
      this.code = "";
      // var callback_url = window.location.href; // 获取页面url
      // var callback_url = "http://953762rl72.zicp.fun"; // 获取页面url
      // var appid = "wxfdd0a46cd0d71084";
      this.gid = this.getUrlCode().gid;
      this.sign = this.getUrlCode().sign; // 截取code
      this.actid = this.getUrlCode().actid; // 截取actid

      if (this.code == null || this.code === "") {
        // 如果没有code，则去请求
        // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
        //   callback_url
        // )}&response_type=code&scope=snsapi_userinfo&state=${
        //   this.state
        // }#wechat_redirect`;
      } else {
        console.log(this.code);
        // serve({
        //   url:
        //     "http://hb.mumisc.com/prod-api/activity/user/getOpenId?code=" +
        //     this.code,
        //   method: "get",
        // }).then((res) => {
        //   console.log(res);

        //   this.str = res.data.msg;

        // });
        // 当code不等于空时，调用后端接口获取用户信息
        // 你自己的业务逻辑

        //获取openid
        // this.getopenid()
      }
    },
    goFn() {
      console.log(this.str);

      this.btnloading = true;
      serve({
        url:
          "https://hb.cnjiexie.cn/prod-api/activity/user/sendOrder?openid=" +
          // "http://192.168.1.161:80/activity/user/sendOrder?openid=" +
          this.gid +
          "&productid=" +
          this.actid +
          "&sign=" +
          this.sign,
        method: "get",
      })
        .then((res) => {
          console.log(res);
          this.btnloading = false;
          alert(res.data.msg);
        })
        .catch((err) => {
          this.btnloading = false;

          alert(err);
        });
    },
    getUrlCode() {
      // 截取url中的code方法
      var url = location.search;
      this.winUrl = url;
      console.log(url);
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box {
  width: 7.5rem;
  height: auto;
  position: relative;
}
.container {
  width: 0;
  height: 0;
  /* background: url(./img/ljjbc.png) no-repeat; */
  /* background-size: 100% 100%; */
}
.imgBox {
  position: absolute;
  top: 8%;
  transform: translateX(-50%);
  left: 50%;
}
.logo {
  width: 0.76rem;
  height: 0.63rem;
}
body {
  /* 750根据设计稿宽度设计 */
  max-width: 750px;
  /* 居中 */
  margin: 0 auto;
}
* {
  padding: 0;
  margin: 0;
}
</style>
