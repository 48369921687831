import Vue from 'vue'
import App from './App.vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'//引入axios

Vue.prototype.$axios = axios;//把axios挂载到vue上
Vue.config.productionTip = false
Vue.use(Element, {})
new Vue({
  render: h => h(App),
}).$mount('#app')
