/*
 * @Author: 刘浩岩 447012389@qq.com
 * @Date: 2024-08-20 18:01:06
 * @LastEditors: 刘浩岩 447012389@qq.com
 * @LastEditTime: 2024-08-20 18:01:13
 * @FilePath: \my-project\src\api\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API
})
export default service