<template>
  <div id="app">
    <Index msg="Welcome to Your Vue.js App" />
  </div>
</template>
<script src="https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js"></script>
<script>
import Index from "./components/index.vue";

export default {
  name: "App",
  components: {
    Index,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */

  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
